<template lang="pug">
    .contacts
        .mapouter.w-100
            .gmap_canvas.w-100
                iframe#gmap_canvas(src="https://maps.google.com/maps?q=okaycars%20puianello&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0")
        .container
            .row.py-5.mt-3
                .col-12.col-md-4
                    h5 Vienici a trovare!
                    p Ci trovi in  
                        b Via G. di Vittorio, 41 A/B - cap 42020 - Loc. Forche di Puianello - Quattro Castella - RE
                    h5.mt-5 Contattaci   
                    .d-flex
                        .primary-button.mt-2 
                            font-awesome-icon(icon="phone")
                            b  0522 889888 
                    p.mt-3 Oppure scrivi a 
                        b info@okaycars.it
                .col-12.col-md-8
                    h5 Vuoi maggiori informazioni? Compila la richiesta!
                    form.form(v-if="!success")
                        .form-group.row
                            .col-12.col-md-6.mt-2
                                label.form-label(for="") Il tuo nome *
                                input.form-control(v-model="name")
                            .col-12.col-md-6.mt-2
                                label.form-label(for="") La tua e-mail *
                                input.form-control(v-model="email") 
                            .col-12.col-md-6.mt-2
                                label.form-label(for="") La tua richiesta *
                                textarea.form-control(v-model="request")  
                        .mt-3.col-12.col-md-6
                            input(type="checkbox" v-model="checkPrivacy") 
                            span.mx-2 Dichiaro di aver letto 
                                router-link(to="privacy-policy" target="_blank") l'informativa
                                |  e, con specifico riferimento alle finalità, presto consenso al trattamento dei dati
                        .d-flex
                            button.primary-button.mt-3(@click="sendRequest" :disabled="!checkPrivacy" :class="!checkPrivacy ? 'opacity-50' : ''")
                                b Invia richiesta
                        .col-12.mt-3 {{error}}

                    .success.mt-3(v-if="success")
                        h5 Richiesta inviata!
</template>
<script>
import Parse from 'parse'
export default {
  name: 'Contacts',
  components: {
  },
  data(){
      return {
          error: null,
          name: "",
          email: "",
          request: "",
          success: false,
          checkPrivacy: false
      }
  },
  methods: {
      sendRequest(e){
          e.preventDefault()
          
          if(!this.validateEmail(this.email) || this.name == "" || this.request == ""){
              this.error = "Compila i campi richiesti"
              return
          }

          Parse.Cloud.run('contactEmail', {email: this.email, name: this.name, text: this.request})
          .then(result => {
              console.log(result)
              this.success = true
          })
          .catch(e => console.log(e))
      },
      validateEmail(email) {
            const re = /\S+@\S+\.\S+/;
            return re.test(email);
        }
  }
}
</script>

<style lang="less" scoped>
    @import '../general.less';
    .carousel {
        height: 60vh;
        background-color: @temp;
        background-position: center;
        background-size: cover;
    }

    #gmap_canvas {
        width: 100%;
        min-height: 500px;
    }
</style>
