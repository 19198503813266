import Vue from 'vue'
import Router from 'vue-router'
import {Homepage, Search, VehicleDetail, NotFound, Sell, Who, Contacts, Privacy } from '../pages'

Vue.use(Router)

const router = new Router({
    mode: "history",
    routes: [
        {
            path: '/',
            name: 'home',
            component: Homepage
        },
        {
            path: '/search/:category?',
            props: true,
            name: 'search',
            component: Search
        },
        {
            path: '/vehicle/:id',
            name: 'vehicle',
            props: true,
            component: VehicleDetail
        },
        {
            path: '/vendi',
            name: 'sell',
            component: Sell
        },
        {
            path: '/chi-siamo',
            name: 'who',
            component: Who
        },
        {
            path: '/contattaci',
            name: 'contacts',
            component: Contacts
        },
        {
            path: '/privacy-policy',
            name: 'privacy',
            component: Privacy
        },
        {
            path: '*',
            name: 'notFound',
            component: NotFound
        },
    ],
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

router.beforeEach((to, from, next) => {
    next()
})

export default router